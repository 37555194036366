<template>

  <button type="button" data-bs-target="#cart-offcanvas-instance" data-bs-toggle="offcanvas" aria-controls="cart-offcanvas" class="btn btn-success position-relative">
    {{ t('shoppingCart.name')}}
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
      {{ items.length }}
      <span class="visually-hidden">{{ t('shoppingCart.noOfJobsInCart') }}</span>
    </span>
  </button>

  <teleport to="#cart-offcanvas">
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" id="cart-offcanvas-instance" tabindex="-1" aria-labelledby="cart-offcanvas-label">
      <div class="offcanvas-header">
        <h5 id="cart-offcanvas-label">{{ t('shoppingCart.name') }}</h5>
        <button type="button" data-bs-target="#cart-offcanvas-instance" data-bs-toggle="offcanvas" class="btn-close text-reset" :aria-label="t('close')"></button>
      </div>
      <div class="offcanvas-body">
        <template v-if="items.length > 0">
          <div v-for="item in items" :key="item['id']" class="card mb-1">
            <div class="card-body">
              <h5 class="card-title">{{ item['name'] }}</h5>
              <p class="card-subtitle">{{ item['displayInfo'] }}</p>
              <p class="card-text">{{ t('shoppingCart.quantity') }}: {{ item['quantity'] }}</p>
              <a href="" @click.prevent="removeFromCart(item['id'])" class="card-link">{{ t('shoppingCart.removeFromCart') }}</a>
            </div>
          </div>
          <button class="btn btn-success mt-1" @click="showOrderModal = true" data-bs-target="#cart-offcanvas-instance" data-bs-toggle="offcanvas">{{ t('shoppingCart.toCheckout') }}</button>
        </template>
        <div v-else>{{ t('shoppingCart.isEmpty') }}</div>
      </div>
    </div>
  </teleport>

  <tt-order-modal ref="cartOrderModal" modal-ref-name="cartOrderModal" @checkout="submitCheckout" :orderBtnLoading="orderBtnLoading" :show="showOrderModal" @cancel="showOrderModal = false" :title="t('shoppingCart.order')" :cancel-btn-label="t('cancel')" :order-btn-label="t('shoppingCart.placeOrder')">
    <component :is="orderFormComponent" v-model:orderInfo="orderInfo" :showDelivery="showDelivery" ref="orderForm"/>
  </tt-order-modal>

</template>

<script>
import { computed, reactive, ref } from 'vue'
import  { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import OrderModal from './components/OrderModal'
import ValidationErrorsToast from './components/toasts/ValidationErrorsToast'
import TitleAndMessageToast from './components/toasts/TitleAndMessageToast'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CartApp',
  components: { 'tt-order-modal': OrderModal },
  setup() {

    const store = useStore()
    const toast = useToast()
    const { t } = useI18n()
    const orderInfo = reactive({})

    const orderForm = ref(null)
    const showOrderModal = ref(false)
    const orderBtnLoading = ref(false)

    function removeFromCart(id) { store.dispatch('cart/removeFromCart', id) }
    function submitCheckout() {
      orderForm.value.v$['orderInfo'].$touch()
      if(orderForm.value.v$['orderInfo'].$invalid) toast.error({ component: ValidationErrorsToast, props: { errors: orderForm.value.v$['orderInfo'].$errors }})
      else {
        store.dispatch('cart/checkout', orderInfo).then(() => {
          showOrderModal.value = false
          toast.success(t('shoppingCart.orderReceived'))
        }).catch(() => {
          toast.error({ component: TitleAndMessageToast, props: { title: t('errors.anErrorOccurred'), message: t('errors.errorOrderSave') }})
        })
      }
    }

    const items = computed(() => [...store.getters['cart/cartItems']].sort((a, b) => { return a.id <= b.id ? -1 : 1}))
    const showDelivery = computed(() => [...store.getters['cart/cartItems']].some(it => it.jobType === 'print'))
    const orderFormComponent = computed(() => `${store.getters['user/organisationId']}-orderform`)

    // beforeCreate
    store.dispatch( 'user/initialize', {
      userId: document.querySelector('#loggedInUser').dataset['userId'],
      organisationId: document.querySelector('#loggedInUser').dataset['organisationId']
    }).then(() => { store.dispatch('cart/initialize') })


    return {
      items,
      showDelivery,
      orderFormComponent,
      orderInfo,
      orderBtnLoading,
      toast,
      t,
      removeFromCart,
      submitCheckout,
      showOrderModal,
      orderForm
    }
  }
}
</script>