<template>
  <h5>{{ title }}</h5>
  <ul>
    <li v-for="error in errors" :key="error.$message" v-html="error.$message"></li>
  </ul>
</template>

<script>
export default {
  name: 'tt-validation-errors-toast',
  emits: ['closeToast'],
  props: {
    title: { type: String, default: 'Var god och rätta följande:' },
    errors: { type: Array, required: true },
    toastId: { type: Number }
  }
}
</script>

<style scoped>
ul {
  margin-left: -1em;
}
</style>