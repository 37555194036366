import findIndex from 'lodash/findIndex'
import cartApi from '../../api/cart-api'

const STATE = () => ({
  orderId: -1,
  orderHash: '',
  items: []
})

const GETTERS = {
  orderId: (state) => { return state.orderId },
  orderHash: (state) => { return state.orderHash },
  cartItems: (state) => { return state.items },
  totalPrice: (state) => {
    return state.items.reduce((total, item) => {
      return total + item['price'] || 0
    }, 0)
  }
}

const ACTIONS = {

  addToCart(ctx, jobDetails) {
    return cartApi.addToCart(jobDetails)
      .then(orderDetails => { ctx.commit('SET_ORDER_DETAILS', { ...orderDetails, userId: ctx.rootGetters['user/userId'] }) })
      .catch(error => { throw error })
  },
  addFileToCart(ctx, jobDetails) {
    return cartApi.addFileToCart(jobDetails)
      .then(orderDetails => { ctx.commit('SET_ORDER_DETAILS', { ...orderDetails, userId: ctx.rootGetters['user/userId'] }) })
      .catch(error => { throw error })
  },

  removeFromCart(ctx, id) {
    return cartApi.removeFromCart(id)
      .then(orderDetails => { ctx.commit('SET_ORDER_DETAILS', { ...orderDetails, userId: ctx.rootGetters['user/userId'] }) })
      .catch(error => { throw error })
  },

  checkout(ctx, orderInfo) {
    const savedCartItems = [...ctx.state.items] // save the cart in case of error
    ctx.commit('SET_CART_ITEMS', { items: [] }) // empty the cart
    try { cartApi.checkoutCart(orderInfo) } // call server checkout
    catch(e) { // check for errors
      ctx.commit('SET_CART_ITEMS', { items: savedCartItems }) // rollback the cart on error
    }
  },

  submitDirectCheckout(ctx, order) {
    return cartApi.checkoutJob(order)
  },

  initialize(ctx) {
    const userId = ctx.rootGetters['user/userId']
    const idKey = `${userId}-orderId`
    const hashKey = `${userId}-orderHash`
    if(localStorage.getItem(idKey) && localStorage.getItem(hashKey)) {
      ctx.commit('INITIALIZE', {
        orderId: localStorage.getItem(idKey),
        orderHash: localStorage.getItem(hashKey)
      })
    }
    return cartApi.getCart()
      .then(orderDetails => { ctx.commit('SET_ORDER_DETAILS', { ...orderDetails, userId } ) })
      .catch(error => { throw error })
  }
}

const MUTATIONS = {
  SET_ORDER_DETAILS(state, { id, hash, items, userId }) {
    localStorage.setItem(`${userId}-orderId`, id)
    localStorage.setItem(`${userId}-orderHash`, hash)
    state.orderId = id
    state.orderHash = hash
    state.items = items
  },
  REMOVE_FROM_CART(state, { item }) { state.items.splice(findIndex(state.items, it =>  it === item), 1) },
  SET_CART_ITEMS(state, { items }) { state.items = items },
  INITIALIZE(state, { orderId, orderHash }) {
    state.orderId = orderId
    state.orderHash = orderHash
  }
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS
}