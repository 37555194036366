const state = {
  messages: []
}
const getters = {
  getMessages: state => state.messages
}
const actions = {
  showMessage ({ commit }, msg) {
    commit('PUSH_MESSAGE', msg)
  },
  hideMessage ({ commit }) {
    commit('SHIFT_MESSAGE')
  }
}
const mutations = {
  PUSH_MESSAGE (state, message) {
    return state.messages.push(message)
  },
  SHIFT_MESSAGE (state) {
    return state.messages.shift()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

