<template>
  <h5>{{ title }}</h5>
  <p>{{ message }}</p>
</template>

<script>
export default {
  name: 'tt-validation-errors-toast',
  emits: ['closeToast'],
  props: {
    title: { type: String, required: true },
    message: { type: String, required: true },
    toastId: { type: Number }
  },
}
</script>