const STATE = {
  loading: false
}

const GETTERS = {
  isLoading: state => state.loading
}

const ACTIONS = {
  setLoading: (ctx, isLoading) => ctx.commit('SET_LOADING', isLoading)
}

const MUTATIONS = {
  SET_LOADING: (state, isLoading) => { state.loading = isLoading }
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS
}
