import { createRouter, createWebHistory } from 'vue-router'

const DalaEnergiLeverantorsbyte = () => import('../components/dalaenergi/Leverantorsbyte')
const DalaEnergiDriftavisering = () => import('../components/dalaenergi/Driftavisering')
const DalaEnergiMatarbyte = () => import('../components/dalaenergi/Matarbyte')
const DalaEnergiAvtal = () => import('../components/dalaenergi/Avtal.vue')
const EjendalsVisitkort = () => import('../components/ejendals/Visitkort')
const LeksandsKommunVisitkort = () => import('../components/leksandskommun/Visitkort')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/dalaenergi/trycksak/leverantorsbyte', component: DalaEnergiLeverantorsbyte },
    { path: '/dalaenergi/trycksak/driftavisering', component: DalaEnergiDriftavisering },
    { path: '/dalaenergi/trycksak/matarbyte', component: DalaEnergiMatarbyte },
    { path: '/dalaenergi/trycksak/avtal', component: DalaEnergiAvtal },
    { path: '/ejendals/trycksak/visitkort', component: EjendalsVisitkort },
    { path: '/leksandskommun/trycksak/visitkort', component: LeksandsKommunVisitkort }
  ]
})

export default router