const STATE = {
  organisationId: '',
  userId: ''
}

const GETTERS = {
  organisationId: state => { return state.organisationId },
  userId: state => { return state.userId }
}

const ACTIONS = {
  initialize(ctx, { userId, organisationId }) {
    ctx.commit('SET_ORGANISATION_ID', organisationId)
    ctx.commit('SET_USER_ID', userId)
  }
}

const MUTATIONS = {
  SET_ORGANISATION_ID(state, id) { state.organisationId = id },
  SET_USER_ID(state, id) { state.userId = id }
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS
}
