import axios from 'axios'
import store from '../store'

const apiClient = axios.create({
  baseURL: `${window['TT_CTX']?.contextPath || '/'}order`,
  withCredentials: false,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getCart() {
    return new Promise((resolve, reject) => {
      store.dispatch('applicationState/setLoading', true)
      apiClient
        .get('', { params: { id: store.getters['cart/orderId'], hash: store.getters['cart/orderHash'] }, headers: { 'X-Requested-With': 'XMLHttpRequest' } })
          .then(response => { resolve(response.data) })
          .catch(error => { reject(error) })
          .then(() => { store.dispatch('applicationState/setLoading', false) })
    })
  },
  addToCart(jobDetails) {
    return new Promise((resolve, reject) => {
      store.dispatch('applicationState/setLoading', true)
      apiClient
        .post('add-to-cart', { id: store.getters['cart/orderId'], hash: store.getters['cart/orderHash'], jobDetails }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
          .then(response => { resolve(response.data) })
          .catch(error => { reject(error) })
          .then(() => { store.dispatch('applicationState/setLoading', false)})
    })
  },
  removeFromCart(jobId) {
    return new Promise((resolve, reject) => {
      store.dispatch('applicationState/setLoading', true)
      apiClient
        .post('remove-from-cart', { id: store.getters['cart/orderId'], hash: store.getters['cart/orderHash'], jobId }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => { resolve(response.data) })
        .catch(error => { reject(error) })
        .then(() => { store.dispatch('applicationState/setLoading', false)})
    })
  },
  checkoutCart(orderInfo) {
    return new Promise((resolve, reject) => {
      store.dispatch('applicationState/setLoading', true)
      apiClient
        .post('checkout-cart', { id: store.getters['cart/orderId'], hash: store.getters['cart/orderHash'], orderInfo }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => { resolve(response.data) })
        .catch(error => { reject(error) })
        .then(() => { store.dispatch('applicationState/setLoading', false)})
    })
  },
  checkoutJob(order)  {
    return new Promise((resolve, reject) => {
      store.dispatch('applicationState/setLoading', true)
      apiClient
        .post('checkout-job', {
          organisation: order.organisationId,
          template: order.job.template,
          orderInfo: order.info,
          jobInfo: order.job.info,
          jobVars: order.job.vars
        }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => { resolve(response.data) })
        .catch(error => { reject(error) })
        .then(() => { store.dispatch('applicationState/setLoading', false)})
    })
  }
}