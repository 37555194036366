<template>
  <div class="modal fade" ref="orderModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" @click="$emit('cancel')" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot/>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" @click="$emit('cancel')" class="btn btn-danger">{{ cancelBtnLabel}}</button>
          <button type="button" @click="$emit('checkout')" :disabled="orderBtnLoading" class="btn btn-success ms-1" name="checkoutBtn">
            <span v-if="orderBtnLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{ orderBtnLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watch } from 'vue'
import { Modal } from 'bootstrap'

export default {
  name: 'tt-order-modal',
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: 'Beställning' },
    cancelBtnLabel: { type: String, default: 'Cancel' },
    orderBtnLabel: { type: String, default: 'Beställ direkt' },
    orderBtnLoading: { typs: Boolean, default: false }
  },
  emits: ['checkout', 'cancel'],
  setup(props) {
    let modal
    const orderModal = ref(null)
    watch(() => props.show, (val) => { modal[val ? 'show' : 'hide']() })
    onMounted(() => { modal = new Modal(orderModal.value) })
    return { orderModal }
  }
}
</script>